import React from 'react';
import Toggle from 'material-ui/Toggle';
import {
    TextField,
    Checkbox as MUCheckbox,
    IconButton,
    DatePicker,
    SelectField,
    MenuItem,
    RadioButton,
    RadioButtonGroup
} from 'material-ui';
import { ActionInfo, ActionLock } from 'material-ui/svg-icons';
import { general, helpers, h } from '../helpers';
import _ from 'lodash';
import AutoComplete from 'material-ui/AutoComplete';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

/**
 * export form mode values
 * @type {{CREATE: string, UPDATE: string, VIEW: string, DELETE: string}}
 */
export var FORM_MODE = {
    CREATE: 'create',
    UPDATE: 'update',
    VIEW: 'view',
    DELETE: 'delete'
};

/**
 * generic handler for form fields on change event
 * @param e
 * @param fields
 * @returns {*}
 * @constructor
 */
export function onFormChange (e, fields, validate) {
    if (fields && e && e.target) {
        var key = e.target.name;
        var value = e.target.value;

        fields[key].value = value;
        fields[key].error = '';
        fields[key].dirty = true;
    }

    validate(key);

    return fields;
}

/**
 * generic handler for form select fields on change event
 * @param key
 * @param value
 * @param fields
 * @param validate
 * @returns {*}
 */
export function onSelectChange (key, value, fields, validate) {
    fields[key].value = value;
    fields[key].error = '';
    validate(key);
    return fields;
}

/**
 * generic handler for form autocomplete fields on change event
 * @param key
 * @param value
 * @param fields
 * @param validate
 * @returns {*}
 */
export function onAutoCompleteChange (key, value, fields, validate) {
    fields[key].value = value;
    fields[key].error = '';
    validate(key);
    return fields;
}

/**
 * generic handler for form submission event
 * @param fields
 * @param validate
 * @returns {boolean}
 */
export function onFormSubmit (fields, validate) {
    var isValid = true;

    for (var key in fields) {
        validate(key);
    }

    for (var key1 in fields) {
        if (fields[key1].error && fields[key1].error !== '') {
            isValid = false;
        }
    }

    return isValid;
}

/**
 * standard HTML input
 */
export class Input extends React.Component {

    render () {
        const field = this.props;
        var attr = _.cloneDeep(field),
            className = field.className || '';

        // remove unnecessary attributes
        attr = general.deleteObjectKey(attr, 'locked');
        attr = general.deleteObjectKey(attr, 'hasInfo');
        attr = general.deleteObjectKey(attr, 'infoText');

        if (field.locked || Boolean(field.disabled)) {
            if (className.indexOf('disabled-textfield') === -1) {
                className = className + ' disabled-textfield ';
            }
        }

        return (
            <div
                className={((field.data.dirty && (field.data.error ? 'has-error ' : 'has-success ')) || '') + 'form-group'}>
                <div>
                    <TextField
                        {...attr}
                        className={className}
                        placeholder={field.placeholder}
                        type={field.type}
                        value={field.data.value}
                        errorText={field.data.dirty && field.data.error ? field.data.error : ''}
                        disabled={Boolean(field.data.disabled) || Boolean(field.disabled) ? true : false}
                    />

                    {field.hasInfo && field.infoText &&
                    <IconButton tooltip={<div style={{ width: '200px', whiteSpace: 'normal', textAlign: 'left' }}>{field.infoText}</div>}
                                className="float-right"
                                iconStyle={{width: '20px', height: '20px', marginTop: '-35px', marginRight: '10px'}}
                                style={{width: '32px', height: '10px', marginTop: '-35px'}}
                                tooltipStyles={{wordWrap: 'break-word'}}
                                touch={true} tooltipPosition="top-left">
                        <ActionInfo />
                    </IconButton>
                    }

                    {!field.hideLock && field.locked &&
                    <IconButton className="float-right"
                                iconStyle={{width: '20px', height: '20px', marginTop: '-35px', marginRight: '10px'}}
                                style={{width: '32px', height: '10px', marginTop: '-35px'}}
                                touch={false} tooltipPosition="top-left">
                        <ActionLock />
                    </IconButton>
                    }
                </div>
            </div>
        );
    }
}

export class ReduxInput extends React.Component {

    render () {
        const { input, label, type, fullWidth, meta: { asyncValidating, touched, error, dirty}} = this.props;

        return (
            <div
                className={(dirty && (error ? 'has-error ' : 'has-success ') || '') + 'form-group'}>
                <div className={asyncValidating ? 'async-validating' : ''}>
                    <TextField {...input} type={type}
                               floatingLabelText={label}
                               errorText={dirty && error ? error : ''}
                                />
                </div>
            </div>
        );
    }
}

/**
 * phone HTML input
 * https://github.com/catamphetamine/react-phone-number-input
 * http://catamphetamine.github.io/react-phone-number-input/docs/styleguide/index.html#phoneinput
 */
export class MUPhoneInput extends React.Component {

    render () {
        const field = this.props;
        var attr = _.cloneDeep(field),
            className = field.className || '';

        // remove unnecessary attributes
        attr = general.deleteObjectKey(attr, 'locked');
        attr = general.deleteObjectKey(attr, 'hasInfo');
        attr = general.deleteObjectKey(attr, 'infoText');

        if (field.locked || Boolean(field.disabled)) {
            if (className.indexOf('disabled-textfield') === -1) {
                className = className + ' disabled-textfield ';
            }
        }

        return (
            <div
                className={((field.data.dirty && (field.data.error ? 'has-error ' : 'has-success ')) || '') + 'form-group'}>
                <div>

                    {attr.floatingLabelText &&
                    <label style={{
                        paddingTop: 14,
                        color: "rgba(0, 0, 0, 0.3)",
                        fontSize: 12,
                        fontFamily: "\"Fira Sans\", sans-serif",
                        fontWeight: 400
                    }}>{attr.floatingLabelText}</label>}

                    <PhoneInput
                        {...attr}
                        className={className}
                        placeholder={field.placeholder}
                        type={field.type}
                        value={field.data.value}
                        errorText={field.data.dirty && field.data.error ? field.data.error : ''}
                        disabled={Boolean(field.data.disabled) || Boolean(field.disabled) ? true : false}
                    />

                    {field.hasInfo && field.infoText &&
                    <IconButton tooltip={<div style={{ width: '200px', whiteSpace: 'normal', textAlign: 'left' }}>{field.infoText}</div>}
                                className="float-right"
                                iconStyle={{width: '20px', height: '20px', marginTop: '-35px', marginRight: '10px'}}
                                style={{width: '32px', height: '10px', marginTop: '-35px'}}
                                tooltipStyles={{wordWrap: 'break-word'}}
                                touch={true} tooltipPosition="top-left">
                        <ActionInfo />
                    </IconButton>
                    }

                    {!field.hideLock && field.locked &&
                    <IconButton className="float-right"
                                iconStyle={{width: '20px', height: '20px', marginTop: '-35px', marginRight: '10px'}}
                                style={{width: '32px', height: '10px', marginTop: '-35px'}}
                                touch={false} tooltipPosition="top-left">
                        <ActionLock />
                    </IconButton>
                    }
                </div>
            </div>
        );
    }
}

/**
 * HTML toggle
 */
export class MUToggle extends React.Component {

    render () {
        const field = this.props;

        return (
            <Toggle
                {...field}
                onToggle={field.onChange.bind(this)}
                toggled={parseInt(field.data.value, 10) === 1 ? true : false}
                defaultToggled={false}
                value={field.data.value}
            />
        );
    }

}

/**
 * HTML checkbox
 */
export class Checkbox extends React.Component {

    render () {
        const field = this.props;

        switch (field.checkboxType) {
            default:
            case 'inline':
                return (
                    <MUCheckbox
                        label={field.label}
                        name={field.name}
                        value={field.data.value}
                        checked={parseInt(field.data.value, 10) === 1 ? true : false}
                        onCheck={field.onChange.bind(this)}
                        iconStyle={this.props.iconStyle? this.props.iconStyle: {}}
                        style={this.props.style? this.props.style: {}}
                        labelStyle={this.props.labelStyle? this.props.labelStyle: {}}
                    />
                );
        }
    }

}

// export class ReduxCheckbox extends React.Component {
//
//     render () {
//         const { input, label, type, meta: { asyncValidating, touched, error, dirty}} = this.props;
//
//         return (
//             <MUCheckbox
//                 {...input}
//                 label={label}
//                 type={type}
//                 checked={input.checked}
//                 onCheck={input.onChange.bind(this)}
//             />
//         );
//     }
// }

/**
 * material design datepicker field
 */
export class MUDatePicker extends React.Component {

    render () {
        const field = this.props;
        var attr = _.cloneDeep(field);

        // remove unnecessary attributes
        attr = general.deleteObjectKey(attr, 'locked');
        attr = general.deleteObjectKey(attr, 'value');

        return (
            <div
                className={((field.data.dirty && (field.data.error ? 'has-error ' : 'has-success ')) || '') + 'form-group'}>
                <div>
                    <DatePicker {...attr}
                                value={field.data.value ? new Date(field.data.value) : ''}
                                errorText={field.data.dirty && field.data.error ? field.data.error : ''}/>

                    {field.locked &&
                    <IconButton className="float-right"
                                iconStyle={{width: '20px', height: '20px', marginTop: '-35px', marginRight: '10px'}}
                                style={{width: '32px', height: '10px', marginTop: '-35px'}}
                                touch={false} tooltipPosition="top-left">
                        <ActionLock />
                    </IconButton>
                    }
                </div>
            </div>
        );
    }

}

/**
 * material design select field
 */
export class MUSelect extends React.Component {

    render () {
        const { data, options, locked } = this.props;
        var menuItems = [];
        var attr = _.cloneDeep(this.props);

        // multiple selection enabled
        if (helpers.general.notEmpty(attr.multiple) && helpers.general.cmpBool(attr.multiple, true)) {
            attr.selectionRenderer = this.selectionRenderer;
        }

        // remove uncessary attributes
        attr = general.deleteObjectKey(attr, 'locked');
        attr = general.deleteObjectKey(attr, 'options');

        for (var i = 0; i < options.length; i++) {
            var option = options[i];
            menuItems.push(
                <MenuItem
                    value={option.value}
                    key={option.value}
                    primaryText={option.text}
                    checked={h.cmpStr(data.value, option.value)}
                />
            );
        }

        return (
            <div
                className={((data.dirty && (data.error ? 'has-error ' : 'has-success ')) || '') + 'form-group'}>
                <div>
                    <SelectField
                        {...attr}
                        value={data.value}
                        errorText={data.dirty && data.error ? data.error : ''}
                    >
                        {menuItems}
                    </SelectField>

                    {locked &&
                    <IconButton className="float-right"
                                iconStyle={{width: '20px', height: '20px', marginTop: '-35px', marginRight: '10px'}}
                                style={{width: '32px', height: '10px', marginTop: '-35px'}}
                                touch={false} tooltipPosition="top-left">
                        <ActionLock/>
                    </IconButton>
                    }
                </div>
            </div>
        );
    }

    selectionRenderer = (values) => {
        const { name, options } = this.props;
        var optionsContext = helpers.general.notEmpty(name) ? helpers.general.prettifyConstant(name) : 'items';
        switch (values.length) {
            case 0:
                return '';
            case 1:
                if (helpers.general.notEmpty(options)) {
                    for (var i = 0; i < options.length; i++) {
                        var option = options[i];
                        if (helpers.general.cmpStr(values[0], option.value)) {
                            return option.text;
                        }
                    }
                } else {
                    return '';
                }
            default:
                return `${values.length} ${optionsContext} selected`;
        }
    }

}

export class MUAutocomplete extends React.Component {
    constructor(){
        super();
        this.state = {
            current_menu: [],
            menu_items: []
        };
        this._search = this._search.bind(this);
    }
    _search(query, dataSource, obj) {
        let self = this;
        const {search, onChange} = this.props;
        if(typeof search === 'function') {
            search(query, function(status, results){
                self.setState({
                    current_menu: results || []
                })
            })
        }
        if(typeof onChange === 'function') {
            onChange(query, dataSource, obj);
        }
    }
    render() {
        const field = this.props;
        var attr = _.cloneDeep(this.props);
        delete attr.search; //Prevent custom component props from propagating
        delete attr.onChange;
        return (
            <div
                className={((field.data.dirty && (field.data.error ? 'has-error ' : 'has-success ')) || '') + 'form-group'}>
                <div>
                    <AutoComplete
                        {...attr}
                        listStyle={{ maxHeight: 200, overflow: 'auto' }}
                        dataSource={this.state.current_menu}
                        onUpdateInput={this._search}
                        filter={(searchText, key) => true}
                        searchText={field.data.value || ""}
                        errorText={field.data.dirty && field.data.error ? field.data.error : ''}
                        fullWidth={true} />
                </div>
            </div>

        );
    }
}

export class ReduxMUSelect extends React.Component {

    render () {
        const { data, options, locked, input, label } = this.props;
        var menuItems = [];
        var attr = _.cloneDeep(this.props);

        // remove uncessary attributes
        attr = general.deleteObjectKey(attr, 'locked');
        attr = general.deleteObjectKey(attr, 'options');

        for (var i = 0; i < options.length; i++) {
            var option = options[i];
            menuItems.push(<MenuItem value={option.value} key={option.value} primaryText={option.text} />);
        }

        return (
            <div
                className={((data.dirty && (data.error ? 'has-error ' : 'has-success ')) || '') + 'form-group'}>
                <div>
                    <SelectField
                        {...attr}
                        {...input}
                        floatingLabelText={label}
                        value={data.value}
                    >
                        {menuItems}
                    </SelectField>

                    {locked &&
                    <IconButton className="float-right"
                                iconStyle={{width: '20px', height: '20px', marginTop: '-35px', marginRight: '10px'}}
                                style={{width: '32px', height: '10px', marginTop: '-35px'}}
                                touch={false} tooltipPosition="top-left">
                        <ActionLock />
                    </IconButton>
                    }
                </div>
            </div>
        );
    }

}

/**
 * HTML radio button group
 */
export class MURadioButtonGroup extends React.Component {

    render () {
        const field = this.props;
        const styles = {
            block: {
                display: 'flex'
            },
            radioButton: {
                marginBottom: 16,
                marginRight: 12,
                width: 'auto'
            },
            radioButtonLabel: {
                marginLeft: -10
            }
        };
        var options = field.options || [],
            radioButtons = [];

        // generate array of radio buttons
        for (var i = 0; i < options.length; i++) {
            var option = options[i];
            radioButtons.push(<RadioButton value={option.value} key={option.value}
                                           label={option.text}
                                           labelStyle={styles.radioButtonLabel}
                                           style={styles.radioButton} />);
        }
        return (
            <RadioButtonGroup {...field}
                              name={field.name}
                              style={styles.block}
                              valueSelected={parseInt(field.data.value, 10) || 0}>
                {radioButtons}
            </RadioButtonGroup>
        );
    }

}

/**
 * render input file type with error message (if any)
 */
export class MUFile extends React.Component {

    render () {
        const { data, input, supportedFileTypes, supportedFileTypesLabel } = this.props;
        var attr = _.cloneDeep(this.props);
        var isHaveError = (data && data.dirty && data.error) ? true : false;
        var supportedFileTypesText = h.notEmpty(supportedFileTypes) ? supportedFileTypes : '';
        return (
            <div className={(isHaveError ? 'has-error ' : 'has-success ') + 'form-group'}>
                <input type="file"
                   style={(isHaveError ? {'border': '2px solid #f44336'} : {})}
                   {...attr}
                   {...input}
                />
                {h.notEmpty(supportedFileTypes) && <small className="text-muted">{h.notEmpty(supportedFileTypesLabel) ? supportedFileTypesLabel : 'Supported file types'}: {supportedFileTypesText}</small>}
                {data && data.dirty && data.error && <div style={{color: '#f44336'}}>{data.error}</div>}
            </div>
        );
    }
}

export class NamedFormComponent extends React.Component {
    constructor (props) {
        super();
        const { delegate, identifier, mode: form_mode } = props;
        this._is_nfc = true; //Flag to show that this is a NamedFromComponent
        let formdata = (props && props.formdata) ? props.formdata : {};
        let default_mode = (props && props.formdata && Object.keys(props.formdata).length > 0) ? 'update' : 'new';
        let mode = form_mode || default_mode; //Set the final form mode based on whether props are provided
        this._child_form_components = {};
        this.nfc_state = {
            formdata: formdata ? formdata : {}, //Raw form data
            _internal: {
                properties: {
                    mode: mode ? mode : 'new'
                }
            }
        } //Component synchronous store
        this.state = {
            fields: this._buildFieldsFromFormData(formdata),
            // fields: formdata ? this.createFieldsFromFormData(formdata) : {},
            validations: {}, //Keys are the identifiers, values are functions that return an object containing an error in the error property
            //Example: name is the identifier
            /*
            validations: {
                name: (value) => {
                    if(!value){
                        return {
                            error: 'Name cannot be empty'
                        }
                    }
                }
            }
            */
        }
        this.Constants = {
            EMPTY_FIELD: {
                value: '',
                error: '',
                dirty: false,
                changed: false
            }
        }
        if(delegate) { //If a delegate is provided, register this component with the delegate
            if (!identifier) {
                console.error('Failed to register delegate for component without identifier');
            }
            else if(!typeof delegate.registerChild === 'function'){
                console.error('Failed to register delegate that is not a NamedFormComponent');
            }
            else {
                delegate.registerChild(identifier, this);
            }
        }
    }
    setNFCStateDeltaSync(delta_or_modifier) {
        let self = this;
        if(typeof delta_or_modifier === 'object') {
            let delta = delta_or_modifier;
            Object.keys(delta_or_modifier).map(
                key => {
                    self.nfc_state[key] = delta[key];
                }
            );
        }
        else if (typeof delta_or_modifier === 'function'){
            let modifier = delta_or_modifier; //Modifier is a function that takes in an old state and returns a new one
            self.nfc_state = modifier(JSON.parse(JSON.stringify(self.nfc_state))); //Ensures that modifications to the object passed are not updated here
        }
        else {
            console.error(`NamedFormComponent.setNFCStateDeltaSync: Invalid type for delta_or_modifier.`);
        }
    }
    /**
     * Add a child to the list of children form components in the recursive structure
     * @param {*} identifier
     * @param {*} child
     */
    registerChild(identifier, child){
        let { _child_form_components } = this;
        _child_form_components[identifier] = child;
    }

    /**
     * Remove a child component at a given identifier
     * @param {*} identifier
     */
    removeChild(identifier) {
        delete this._child_form_components[identifier];
    }

    /**
     * When the component is unmounted, get the delegate to remove this component
     */
    componentWillUnmount(){
        let { identifier, delegate } = this.props;
        if(identifier && delegate && typeof delegate.removeChild === 'function'){
            delegate.removeChild(identifier);
        }
    }

    render () {
        return (
            <div></div>
        );
    }

    /**
     * Text field change handler
     * @param {string} identifier
     */
    updateStateOnTextFieldChange(identifier){
        let self = this;
        return (event, text) => {
            self._formFieldUpdated(identifier, event, text);
        }
    }

    /**
     * MU Select field changed
     * @param {*} identifier
     */
    updateStateOnMUSelectFieldChange(identifier){
        let self = this;
        return (event, option_index, option_value) => {
            //Update the internal state of form data
            self._formFieldUpdated(identifier, event, option_value);
        }
    }

    /**
     * File upload input changed
     * @param {*} identifier
     */
    updateStateOnFileUploadAdded(identifier){
        let self = this;
        return (event) => {
            var fileList = event.target.files ? event.target.files : [];
            self.processFileUpload(fileList, (err, data) => {
                if(!err){
                    self._formFieldUpdated(identifier, event, data);
                }
                else {
                    console.error(err);
                }
            });
        }
    }

    /**
     * Chip removed at index
     * @param {*} identifier
     * @param {*} index
     */
    updateStateOnFileUploadChipRemoved(identifier, index){
        let self = this;
        return (event) => {
            var files = self.nfc_state.formdata[identifier];
            files.splice(index, 1); //Remove the item from the form data
            self._formFieldUpdated(identifier, event, files);

            self.fileUploadChipRemoved(index, event);
        }
    }

    updateStateOnMUDatePickerChanged(identifier) {
        let self = this;
        return (event, new_date) => {
            var date_str = new_date.toDateString();
            self._formFieldUpdated(identifier, event, date_str);
        }
    }

    updateStateOnMUSelectTimePickerChanged(identifier) {
        let self = this;
        return (event, new_time) => {
            self._formFieldUpdated(identifier, event, new_time);
        }
    }

    /**
     * Generic component change handler for nested NamedFormComponents
     * @param {string} identifier
     */
    updateStateOnFormComponentChanged(identifier){
        let self = this;
        return (event, formdata) => {
            self._formFieldUpdated(identifier, event, formdata);
        }
    }

    /**
     * Generic component change handler for nested NamedFormComponents
     * @param {string} identifier
     */
    updateStateOnMUAutocompleteChanged(identifier){
        let self = this;
        return (query, options, object) => {
            const event = {
                object,
                options
            };
            self._formFieldUpdated(identifier, event, query);
        };
    }
    /**
     * Set a form field's value
     * @param {string} identifier
     * @param {*} data
     */
    setFormField(identifier, data) {
        let self = this;
        self._formFieldUpdated(identifier, null, data);
    }

    _buildFieldsFromFormData(formdata){
        if(!formdata){
            return {};
        }
        var fields = {};
        Object.keys(formdata).forEach(key => {
            var value = formdata[key];
            var field = {};
            field.value = (value !== undefined && value !== null) ? value : "";
            field.error = '';
            field.dirty = false;
            field.changed = false;
            fields[key] = field;
        });
        return fields;
    }
    /**
     * This function updates fields and updates formdata
     * @param {*} identifier
     * @param {*} event
     * @param {*} value
     */
    _formFieldUpdated(identifier, event, value){
        let self = this;
        //Update the internal fields structure - contains meta data such as dirty flags and validation errors
        self._internalUpdateField(identifier, value);
        self._formPropertyUpdated(identifier, event, value);

    }
    /**
     * Update the form data without doing further validation
     * @param {string} identifier
     * @param {Object} event
     * @param {*} data
     */
    _formPropertyUpdated(identifier, event, data){
        let self = this;
        //Update the component state
        event = event ? event : {}; //initalize event in case null is passed in
        event.identifier = identifier;
        self.setNFCStateDeltaSync((nfc_state) => {
            nfc_state.formdata[identifier] = data;
            return nfc_state;
        });

        let { formdata } = self.nfc_state;
        //Update the onChange listeners
        if (typeof self.props.onChange === 'function'){
            self.props.onChange(event, formdata);
        }

        //Update the child components
        self.formDataChanged(event, formdata);
    }

    /**
     * Updates the fields variable with relevant metadata
     * @param {string} identifier
     * @param {*} event
     * @param {Object} value
     */
    _internalUpdateField(identifier, value){
        let self = this;
        self._internalMarkFieldModified(identifier);
        self._internalSetFieldValue(identifier, value);
        self._internalUpdateFieldValidation(identifier, value);
    }
    _internalMarkFieldModified(identifier){
        let self = this;
        var {fields} = self.state;
        fields[identifier] = fields[identifier] ? fields[identifier] : {}; //Initialize the values if not intialized
        fields[identifier].dirty = true; //Mark it as dirty
        fields[identifier].changed = true; //Mark the field as changed
        self.setState({
            fields: fields
        });
    }
    _internalSetFieldValue(identifier, value){
        let self = this;
        var {fields} = self.state;
        //Note: fields are only items that are elements in the form, and not sub forms.
        fields[identifier] = fields[identifier] ? fields[identifier] : {}; //Initialize the values if not intialized
        fields[identifier].value = value; //Update the value
        self.setState({
            fields: fields
        });
    }
    _internalUpdateFieldValidation(identifier, value){
        let self = this;
        var {fields} = self.state;
        fields[identifier] = fields[identifier] ? fields[identifier] : {};
        fields[identifier].error = self._getError(identifier, value); //Get the error value
        self.setState({
            fields: fields
        });
    }
    /**
     * Run validations on the field and get the errors
     * @param {string} identifier
     * @param {string} value
     */
    _getError(identifier, value){
        let self = this;
        let {validations} = self.state;
        let validator = validations[identifier];
        if(!validator){
            return '';
        }
        let result = validator(value);
        if(result && result.error){
            return result.error;
        }
        return ''; //Returns no errors by default
    }
    /**
     * Returns the form data
     */
    getFormData(){
        let self = this;
        return self.nfc_state.formdata ? self.nfc_state.formdata : {};
    }
    initFormDataValue(identifier, value){
        let self = this;
        self.setNFCStateDeltaSync((nfc_state) => {
            nfc_state.formdata[identifier] = value;
            return nfc_state;
        })
        self._internalUpdateField(identifier, value);
    }

    /**
     * Get the field at a given identifier
     * @param {*} identifier
     * @returns {{value: *, error: string, dirty: *, changed: *}}
     */
    getField(identifier){
        let self = this;
        const {fields} = this.state;
        if(!fields || !fields[identifier]){ //Default retun value if a field does not exist
            return self.Constants.EMPTY_FIELD;
        }
        return fields[identifier];
    }
    /**
     * Returns a property in the form for the provided identifier
     * @param {string} identifier
     */
    getFormProperty(identifier){
        let self = this;
        if(self.nfc_state.formdata && self.nfc_state.formdata[identifier]){
            return self.nfc_state.formdata[identifier];
        }
        return;
    }
    getFormErrors(){
        let self = this;
        const {formdata} = self.nfc_state;
        const {validations, fields} = self.state;
        //Ensure that all fields are updated first
        var errors = [];
        Object.keys(validations).forEach(identifier => {
            var error = self._getError(identifier, formdata[identifier]);
            if(error){
                errors.push(error);
            }
        });
        return errors;
    }
    getFormMode(){
        let { mode } = this.nfc_state._internal.properties;
        return mode;
    }
    isFormValidated(){
        let self = this;
        let myid = self.props.identifier;
        let isFormValid = self.getFormErrors().length === 0;
        const { _child_form_components } = this;
        for(const identifier in _child_form_components) {
            const child = _child_form_components[identifier];
            if(child && typeof child.isFormValidated === 'function'){
                isFormValid = isFormValid && child.isFormValidated(); //We do not break so that the checks will trigger all errors
            }
        }
        return isFormValid;
    }

    updateFieldErrors(){
        let self = this;
        const {formdata} = self.nfc_state;
        const {fields, validations} = self.state;
        Object.keys(validations).forEach(identifier => {
            let value = formdata[identifier];
            let current_error = self._getError(identifier, value);
            let field = fields[identifier] ? fields[identifier] : {};
            if(current_error){
                field.value = (typeof field.value === 'undefined') ? "" : field.value; //Set to empty string if no value to prevent errors in text field
                field.error = current_error;
                field.dirty = true; //Hack to force the errors to display in the input field
            }
            else {
                field.error = null; //If there are no errors, clear the last error
            }
            fields[identifier] = field;
        });
        self.setState({
            fields: fields
        });
        //Update children from errors as well
        const {_child_form_components} = this;
        Object.keys(_child_form_components).forEach(key => {
            let child = _child_form_components[key];
            if(child && typeof child.updateFieldErrors === 'function'){
                child.updateFieldErrors();
            }
        })
    }

    /**
     * Default method for changes to form data
     * @param {*} event
     * @param {*} formdata
     */
     formDataChanged(event, formdata){
     }

    /**
     * Default method for process file upload, which will return the default files. Override this method to do some raw processing of data
     * @param {FileList} filelist
     * @param {*} callback
     */
    processFileUpload(filelist, callback){
        callback(null, filelist);
    }

    /**
     * Default handler for removal of upload chips
     * @param {*} fileIndex
     * @param {*} event
     */
    fileUploadChipRemoved(fileIndex, event){
    }


    /**
     * Get a validation function for a specific type
     * @param {string} type
     */
    validationForType(identifier, type){
        switch(type){
            case 'not-empty':
                return (input) => {
                    var result = {};
                    if(!input){
                        result.error = `${identifier}: Field cannot be empty.`;
                        result.dirty = true; //Mark entry as edited by user once validation is run
                    }
                    return result;
                }
            break;
            default: //If the validation type does not match any known types, assume no errors
                return () => {};
            break;
        }
    }
}

/**
 * @extends {React.Component<{className?: string, floatingLabelText: string, name: string, fullWidth:Boolean, onChange: function, data: *, options: { text: string, value: * }[]}>}
 */
export class MUAutoComplete extends React.Component {
    constructor(){
        super();
        this.state = {
            currentSearchText: '',
            selectedOption: null,
            isOptionUpdating: false, //whether the component is currently being updated by the system,
            isSearchTextUpdating: false //whether the search text is currently being updated by the system
        };
        this.latestProps = null;
    }
    componentWillReceiveProps(newProps) {
        let { isOptionUpdating } = this.state;

        if(!isOptionUpdating) {
            this.applyProps(newProps);
        }
        else {
            this.latestProps = newProps; //Store the latest props to prepare for consumption
        }
    }
    async applyProps(newProps) {
        let { currentSearchText, isSearchTextUpdating } = this.state;
        let selectedOption = this.state.selectedOption || {};
        let selectedOptionValue = selectedOption.value || null;
        let selectedOtionDetails = this.getOptionByValue(newProps.data.value);
        if(newProps.data.value !== selectedOptionValue) {
            await this.setSelectedOption(newProps.data.value);
        }
        else if(!isSearchTextUpdating && selectedOtionDetails && selectedOtionDetails.text !== currentSearchText ) {
            await this.setSearchText(selectedOtionDetails.text);
        }
    }
    /**
     * Set the current user visible search text
     * @param {string} searchText
     */
    async setSearchText(searchText) {
        await this.setIsSearchTextUpdating(true);
        searchText = searchText || '';
        await new Promise((resolve, reject) => {
            this.setState({ currentSearchText: searchText }, () => resolve());
        });
        await this.setIsSearchTextUpdating(false);
    }
    async setIsOptionUpdating(isOptionUpdating) {
        await new Promise((resolve, reject) => {
            this.setState({
                isOptionUpdating
            }, () => resolve());
        });
        if(!isOptionUpdating) { //If we are releasing the lock, immediately check of received props and apply them
            if(this.latestProps) {
                let copyProps = this.latestProps;
                this.latestProps = null;
                await this.applyProps(copyProps);
            }
        }
    }
    async setIsSearchTextUpdating(isSearchTextUpdating) {
        await new Promise((resolve, reject) => {
            this.setState({
                isSearchTextUpdating
            }, () => resolve());
        });
    }
    async setSelectedOption(value) {
        let selectedOption = this.getOptionByValue(value);
        if(selectedOption) {
            await this.setIsOptionUpdating(true);
            await new Promise((resolve, reject) => {
                this.setState({
                    selectedOption
                }, () => resolve());
            });
            await this.setSearchText(selectedOption.text);
            await this.setIsOptionUpdating(false);
        }
    }

    /**
     * Get the option corresponding to stated value
     * @param {*} value
     */
    getOptionByValue(value) {
        let { options } = this.props;
        options = options || [];
        let selectedIndex = null;
        let selectedOption = null;
        for(let i = 0; i < options.length; i++) {
            let option = options[i];
            if(option.value === value) {
                selectedIndex = i;
                selectedOption = option;
                break;
            }
        }
        return {
            idx: selectedIndex,
            ...selectedOption
        };
    }
    componentDidMount() {
        (async() => {
            await this.setSearchText(this.props.data.value);
        })();
    }
    /**
     *
     * @param {{ text: string, value: *}} selectedItem
     */
    async onNewRequest(selectedItem) {
        let { onChange = () => {}, options } = this.props;
        options = options || [];
        let selectedOption = this.getOptionByValue(selectedItem.value);
        await this.setSearchText(selectedItem.text);
        this.onChange({
            target: {
                value: selectedOption
            }
        }, selectedOption.idx, selectedItem.value);
    }
    async onUpdateInput(searchText) {
        await this.setSearchText(searchText);
    }

    onChange(event, index, value) {
        const { onChange = () => {} } = this.props;
        onChange(event, index, value);
    }

    render() {
        let { data, onChange = () => {}, options } = this.props;
        var attr = _.cloneDeep(this.props);
        delete attr.search; //Prevent custom component props from propagating
        delete attr.onChange;
        return (
            <div
                className={((data.dirty && (data.error ? 'has-error ' : 'has-success ')) || '') + 'form-group'}>
                <div>

                    <AutoComplete
                        {...attr}
                        searchText={this.state.currentSearchText}
                        dataSource={options}
                        fullWidth={true}
                        multiLine={false}
                        openOnFocus={true}
                        filter={AutoComplete.caseInsensitiveFilter}
                        onNewRequest={this.onNewRequest.bind(this)}
                        onUpdateInput={this.onUpdateInput.bind(this)}
                        errorText={data.dirty && data.error ? data.error : ''}
                    />
                </div>
            </div>

        );
    }
}

/**
 *
 * @param {FormConfig} formConfig
 */
export function withForm(formConfig) {
    /**
     * @param {React.Component} ReactComponent
     */
    return function(ReactComponent) {
        return class extends React.Component {
            constructor(props) {
                let fields = formConfig.getFields();
                super(props);
                let formData = this.computeFormData(fields);
                let disabledFields = this.mapDisabledFields(fields, formData);
                this.state = {
                    fields,
                    validations: formConfig.validations,
                    formData,
                    formErrors: {},
                    disabledFields
                };
            }
            componentWillMount() {
                (async () => {
                    let {fields, formData} = this.state;
                    let disabledFields = await this.mapDisabledFields(fields, formData);
                    await new Promise((resolve, reject) => {
                        this.setState({ disabledFields }, () => resolve() );
                    });
                })().then(() => {

                });
            }
            /**
             * =======================
             * SET FUNCTIONS
             * =======================
             */
            async setFieldValue(field_name, proxy, new_val) {
                let fields = this.state.fields || {};
                let formData = this.state.formData;
                let formErrors = this.state.formErrors;
                let field = fields[field_name];

                //Update the field properties
                let error = await this.getFieldError(field_name, new_val);
                field.error = error;
                field.value = new_val;
                field.dirty = true;

                //Update the field object
                fields[field_name] = field;

                //Update the form data - Pass 1, update form data form the fields
                formData = this.computeFormData(fields);

                //Update the form errors
                formErrors[field_name] = error;

                //Recompute the map of disabled fields
                let disabledFields = await this.mapDisabledFields(fields, formData);

                //Update the component state
                await new Promise((resolve, reject) => {
                    this.setState({
                        fields,
                        formData,
                        formErrors,
                        disabledFields
                    }, () => resolve());
                });
            }

            async setFormData(formDataRaw) {
                let fields = this.state.fields;
                for(let field_name in fields) {
                    let field = fields[field_name];
                    field.value = formDataRaw[field_name];
                    field.error = null;
                }

                let { formData, formErrors, disabledFields } = await this.getFieldsMeta(fields);

                //Update the errors from the meta
                for(let field_name in formErrors) {
                    let error = formErrors[field_name];
                    let field = fields[field_name];
                    field.error = error;
                    fields[field_name] = field;
                }

                //Update the state of the application
                await new Promise((resolve, reject) => {
                    this.setState({ fields, formData, formErrors, disabledFields }, () => resolve() );
                });

                //Perform validation on all fields
                await this.validate();
            }

            /**
             * Generate the form meta data from the fields
             * @param {*} fields
             */
            async getFieldsMeta(fields) {
                //Update the form data - Pass 1, update form data form the fields
                let formData = this.computeFormData(fields);
                let formErrors = {};

                //Check for form errors
                for(let field_name in fields) {
                    let field = fields[field_name];
                    let isFieldDisabled = await this.isFieldDisabled(field_name, fields, formData);
                    if(!isFieldDisabled) {
                        //Update the field errors
                        let field_value = field.value;
                        let field_error = await this.getFieldError(field_name, field_value);
                        formErrors[field_name] = field_error;
                    }
                    //Update the store to prepare to update the state
                    fields[field_name] = field;
                }

                //Recompute the map of disabled fields
                let disabledFields = await this.mapDisabledFields(fields, formData);
                return {
                    formData,
                    formErrors,
                    disabledFields
                };
            }
            /**
             * =======================
             * GET FUNCTIONS
             * =======================
             */
            getFormState() {
                let { formData, formErrors, disabledFields } = this.state;
                //Remove the disabled fields from the form errors and form data
                let formDataProcessed = {};
                let formErrorsProcessed = {};
                for(let field_name in formData) {
                    let isDisabled = disabledFields[field_name];
                    if(!isDisabled) {
                        formDataProcessed[field_name] = formData[field_name];
                        formErrorsProcessed[field_name] = formErrors[field_name];
                    }
                }
                return {
                    formData: formDataProcessed,
                    formErrors: formErrorsProcessed,
                    disabledFields
                };

            }
            async getFieldError(field_name, field_value) {
                let getError = this.state.validations[field_name];
                let formData = this.state.formData;
                let proxy = {
                    formData,
                    value: field_value
                };
                if(typeof getError === 'function') {
                    let error = await getError(proxy);
                    return error;
                }
            }
            /**
             * Check if a field is disabled - synchronous because it is used in the synchronous render function
             * @param {*} field_name
             */
            async isFieldDisabled(field_name, fields, formData) {
                let field = fields[field_name];
                if(field) {
                    let isDisabledRaw = field.isDisabled;
                    let isDisabled = false;
                    switch(typeof isDisabledRaw) {
                        case 'function': {
                            let proxy = {
                                formData
                            };
                            isDisabled = await isDisabledRaw(proxy) ? true : false;
                            break;
                        }
                        case 'number':
                        case 'boolean': {
                            isDisabled = isDisabledRaw ? true : false;
                            break;
                        }
                        case 'undefined': {
                            isDisabled = false;
                            break;
                        }
                        case 'object': {
                            if(isDisabledRaw === null) {
                                isDisabled = false;
                            }
                            else {
                                isDisabled = true;
                            }
                            break;
                        }
                        default: {
                            console.error('isFieldDisabled default:', field_name, fields, formData, isDisabled);
                        }
                    }
                    return isDisabled;
                }
            }
            computeFormData(fields) {
                let newFormData = Object.keys(fields).reduce((state, field_name) => {
                    let field = fields[field_name];
                    state[field_name] = field.value;
                    return state;
                }, {});
                return newFormData;
            }
            computeFormErrors(fields, formData) {
                let formErrors = Object.keys(fields).reduce((state, field_name) => {
                    let field = fields[field_name];
                    state[field_name] = field.error;
                    return state;
                }, {});
                return formErrors;
            }
            async mapDisabledFields(fields, formData) {
                let tasks = [];
                let field_names = [];
                for(let field_name in fields) {
                    field_names.push(field_name);
                    tasks.push(this.isFieldDisabled(field_name, fields, formData));
                }
                let results = await Promise.all(tasks);
                let disabledFields = {};
                for(let i = 0; i <field_names.length; i++ ) {
                    let field_name = field_names[i];
                    let isDisabled = results[i];
                    disabledFields[field_name] = isDisabled;
                }
                return disabledFields;
            }

            // }
            /**
             * =======================
             * HIGHER ORDER FUNCTIONS
             * =======================
             */
            /**
             * Updates form errors
             */
            async validate() {
                let fields = this.state.fields || {};
                let formData = this.state.formData || {};
                let formErrors = this.state.formErrors || {};

                //Check for form errors
                let isValid = true;
                for(let field_name in fields) {
                    let field = fields[field_name];

                    //Update the field errors
                    let field_value = field.value;
                    field.error = await this.getFieldError(field_name, field_value);
                    field.dirty = true;

                    //Update the form errors
                    formErrors[field_name] = field.error;
                    let isFieldDisabled = await this.isFieldDisabled(field_name, fields, formData);
                    //Do a check if errors have occurred if the field is not disabled
                    if(isValid && !isFieldDisabled) {
                        isValid = field.error ? false : true;
                    }

                    //Update the store to prepare to update the state
                    fields[field_name] = field;
                }

                //Update the state for the fields
                await new Promise((resolve, reject) => {
                    this.setState({ fields, formErrors }, () => resolve());
                });
                return isValid;
            }
            /**
             * Gets the change handler for a specific field_name
             * @param {*} field_name
             * @param {*} [element_type]
             */
            handleFieldChange (field_name, element_type) {
                switch (element_type) {
                    case 'checkbox':
                        return async (proxy, value) => {
                            let actual_value = value ? 1 : 0;
                            await this.setFieldValue(field_name, proxy, actual_value);
                        };
                    case 'select':
                        return async (proxy, index, value) => {
                            await this.setFieldValue(field_name, proxy, value);
                        };
                    default:
                        return async (proxy, value) => {
                            await this.setFieldValue(field_name, proxy, value);
                        };
                }
            }
            render() {
                let { formData, formErrors, disabledFields } = this.getFormState();
                return <ReactComponent
                    {...this.props}
                    form={{
                        //Injected methods
                        validate: async () => { return await this.validate(); },
                        isValid: async () => { return await this.validate(); },
                        load: async (formData) => { await this.setFormData(formData); },
                        setFieldValue: async (field_name, value) => { await this.setFieldValue(field_name, {}, value); },
                        //Managed state
                        formData,
                        formErrors,
                        disabledFields,
                        fields: this.state.fields,
                        //Events
                        handleFieldChange: this.handleFieldChange.bind(this)
                    }}
                />;
            }
        };
    };
}
/**
 * @typedef FormConfig
 * @property {GetFields} getFields
 * @property {Object<string, function>} validations
 */
/**
 * @callback GetFields
 * @returns {Object<string, any>}
 */
